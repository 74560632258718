import * as bcrypt from "bcryptjs";
import argon from "argon2-browser";

import { readFileAsArrayBuffer, combineBuffers } from "./utils";
import backend from "./backend";

// HELPER
function hexStringToUint8Array(hexString) {
  if (hexString.length % 2 != 0) throw "Invalid hexString";
  var arrayBuffer = new Uint8Array(hexString.length / 2);

  for (var i = 0; i < hexString.length; i += 2) {
    var byteValue = parseInt(hexString.substr(i, 2), 16);
    if (isNaN(byteValue)) throw "Invalid hexString (single byte value)";
    arrayBuffer[i / 2] = byteValue;
  }

  return arrayBuffer;
}

function bytesToPEM(bytes, title = "PUBLIC KEY") {
  const exportedAsString = String.fromCharCode.apply(
    null,
    new Uint8Array(bytes)
  );
  const exportedAsBase64 = btoa(exportedAsString);

  return `-----BEGIN ${title}-----\n${exportedAsBase64}\n-----END ${title}-----`;
}

function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

function PEMtoBytes(str) {
  const splittedStr = str.split("-----");
  if (splittedStr.length != 5) {
    throw new Exception("Invalid PEM format");
  }

  const base64Content = splittedStr[2];
  const binaryContent = atob(base64Content);
  return str2ab(binaryContent);
}

function bytesToHexString(bytes) {
  if (!bytes) return null;

  bytes = new Uint8Array(bytes);
  var hexBytes = [];

  for (var i = 0; i < bytes.length; ++i) {
    var byteString = bytes[i].toString(16);
    if (byteString.length < 2) byteString = "0" + byteString;
    hexBytes.push(byteString);
  }

  return hexBytes.join("").toUpperCase();
}

function asciiToUint8Array(str) {
  return new TextEncoder("utf-8").encode(str);
}

function bytesToASCIIString(bytes) {
  return new TextDecoder("utf-8").decode(bytes);
}

// derived keys get imported into the crypto.subtle API with the following usage flags.
const DERIVED_KEY_USAGES = Object.freeze([
  "wrapKey",
  "unwrapKey",
  "encrypt",
  "decrypt",
]);
const USED_ENCRYPTION_ALGORITHM = Object.freeze({
  name: "aes-gcm",
  length: 256,
});
const defaultDeriveOptions = () => ({
  // 2023-05-22
  //
  // Reference: OWASP: https://cheatsheetseries.owasp.org/cheatsheets/Password_Storage_Cheat_Sheet.html#introduction
  // time: 2
  // mem: 19*1024 // 19456 KiB = 19 MiB
  // parallelism: 1
  // for password storage,
  //
  // bitwarden uses (as default):
  // time: 3,
  // mem: 64*1024, // 65536 KiB = 64 MiB
  // parallelism: 4,
  // for key derivation
  //
  // if you change these parameters, new keys will automatically be derived with the new parameters.
  // old keys will get reencrypted and derived with the new parameters when they are used the first time.
  // see reEncryptAndReplacePrivateKey
  algorithm: "argon2id",
  time: 3 * 3,
  mem: 2 * 64 * 1024,
  parallelism: 4,
});

const deriveKeyFromPassword = async function (
  password,
  salt = null,
  deriveOptions = null
) {
  const usedSalt = salt ?? (await createNewSalt());

  const usedDeriveOptions = deriveOptions ?? defaultDeriveOptions();
  console.debug("Deriving key with options", usedDeriveOptions);

  const algorithm = (usedDeriveOptions.algorithm ?? "").toLowerCase();
  delete usedDeriveOptions.algorithm;

  if (algorithm === "pbkdf2") {
    const binaryPassword = asciiToUint8Array(password);
    const binarySalt = hexStringToUint8Array(usedSalt);

    const importKey = await crypto.subtle.importKey(
      "raw",
      binaryPassword,
      "PBKDF2",
      false,
      ["deriveKey"]
    );

    const key = await window.crypto.subtle.deriveKey(
      { ...usedDeriveOptions, name: "PBKDF2", salt: binarySalt },
      importKey,
      { ...USED_ENCRYPTION_ALGORITHM },
      false, // This key must not be extracted. It is always created on-the-fly by the user password + salt
      [...DERIVED_KEY_USAGES],
    );

    return {
      key,
      deriveOptions: {
        ...usedDeriveOptions,
        algorithm: "pbkdf2",
        usedDeriveOptions,
      },
      salt: usedSalt,
      derivationIsDeprecated: true,
    };
  } else if (algorithm === "argon2id") {
    const keyHash = await argon.hash({
      ...usedDeriveOptions,
      pass: password,
      salt: usedSalt,
      hashLen: 32, // 256 bits
      type: argon.ArgonType.Argon2id,
    });

    const key = await crypto.subtle.importKey(
      "raw",
      keyHash.hash,
      { ...USED_ENCRYPTION_ALGORITHM },
      false,
      [...DERIVED_KEY_USAGES]
    );

    const defaultOptions = defaultDeriveOptions();
    const deprecationStatus = Object.entries(usedDeriveOptions).map(
      ([key, value]) => defaultOptions[key] > value
    );

    return {
      key,
      deriveOptions: {
        ...usedDeriveOptions,
        algorithm: "argon2id",
      },
      salt: usedSalt,
      derivationIsDeprecated: deprecationStatus.some((x) => x),
    };
  } else {
    console.error("Unknown derivation method", deriveOptions);
    return null;
  }
};

const exportKey = async function (key) {
  return bytesToHexString(await crypto.subtle.exportKey("raw", key));
};

const exportKeyPairPublic = async function (publicKey) {
  return bytesToPEM(await crypto.subtle.exportKey("spki", publicKey));
};

const exportKeyPairPrivate = async function (privateKey) {
  return bytesToHexString(await crypto.subtle.exportKey("pkcs8", privateKey));
};

const importKeyPairPublic = async function (publicKey, extractable = false) {
  const binaryKey = PEMtoBytes(publicKey);
  return await crypto.subtle.importKey(
    "spki",
    binaryKey,
    { name: "rsa-oaep", hash: "sha-256" },
    extractable,
    ["wrapKey"]
  );
};

const importKeyPairPrivate = async function (privateKey, extractable = false) {
  const binaryKey = hexStringToUint8Array(privateKey);
  return await crypto.subtle.importKey(
    "pkcs8",
    binaryKey,
    { name: "rsa-oaep", hash: "sha-256" },
    extractable,
    ["unwrapKey"]
  );
};

const wrapPrivateKey = async function (key, privateKey) {
  const exportedKey = await exportKeyPairPrivate(privateKey);
  return await encrypt(key, exportedKey);
};

const unwrapPrivateKey = async function (
  key,
  wrappedPrivateKey,
  storageEngine = null,
  extractable = false
) {
  const exportedKey = await decrypt(key, wrappedPrivateKey);
  if (storageEngine) {
    await storageEngine.setItem("private_key", exportedKey);
  }
  return await importKeyPairPrivate(exportedKey, extractable);
};

const generateAndUploadNewKeyPair = async function (
  password,
  usesCustomPassword
) {
  // debugger;
  const keyPair = await createNewKeyPair();
  const publicKey = await exportKeyPairPublic(keyPair.publicKey);

  const data = JSON.stringify({
    keypair: {
      public_key: publicKey,
      private_key: await encryptPrivateKey(keyPair, password),
      key_password_enc: usesCustomPassword ? bcrypt.hashSync(password) : null,
    },
  });

  await $.ajax("/crypto/private_keys.json", {
    method: "POST",
    dataType: "json",
    contentType: "application/json; charset=utf-8",
    data,
  });

  return;
};

const reEncryptAndReplacePrivateKey = async function (
  extractablePrivateKey,
  password
) {
  console.log("Re-encrypting private key")

  const privateKey = await encryptPrivateKey(
    {
      privateKey: extractablePrivateKey,
    },
    password
  );

  await $.ajax("/crypto/private_keys/reencryption.json", {
    headers: window.CSRF_Token ? {
      "X-CSRF-Token": window.CSRF_Token,
    }: undefined,
    method: "POST",
    dataType: "json",
    contentType: "application/json; charset=utf-8",
    data: JSON.stringify({
      keypair: {
        private_key: privateKey,
      },
    }),
  });
};

const encryptPrivateKey = async function (keyPair, password) {
  const keyDerivation = await deriveKeyFromPassword(password);
  const encryptedPrivateKey = await wrapPrivateKey(
    keyDerivation.key,
    keyPair.privateKey
  );

  return {
    ciphertext: encryptedPrivateKey,
    salt: keyDerivation.salt,
    key_derivation_options: keyDerivation.deriveOptions,
  };
};

const encryptBinary = async function (key, messageBinary) {
  const iv = new Uint8Array(96 / 8);
  await crypto.getRandomValues(iv);

  const cipherBinary = await crypto.subtle.encrypt(
    { name: "aes-gcm", iv: iv },
    key,
    messageBinary
  );

  return {
    iv: iv,
    cipherBinary: cipherBinary,
  };
};

const decryptBinary = async function (ivBinary, key, cipherBinary) {
  return await crypto.subtle.decrypt(
    { name: "aes-gcm", iv: ivBinary },
    key,
    cipherBinary
  );
};
// HELPER END

// PUBLIC
const decrypt = async function (key, cipher) {
  const ivLength = 12 /* byte size of IV */ * 2; /* hex nibbles per byte */
  const parsedCipher = {
    iv: cipher.substring(0, ivLength),
    ciphertext: cipher.substring(ivLength),
  };

  const ivBinary = hexStringToUint8Array(parsedCipher.iv);
  const cipherBinary = hexStringToUint8Array(parsedCipher.ciphertext);

  const messageBinary = await decryptBinary(ivBinary, key, cipherBinary);
  return bytesToASCIIString(messageBinary);
};

const wrapKey = async function (publicKey, key) {
  return bytesToHexString(
    await crypto.subtle.wrapKey("raw", key, publicKey, "rsa-oaep")
  );
};

const unwrapKey = async function (privateKey, wrappedKey, extractable = false) {
  const binaryWrappedKey = hexStringToUint8Array(wrappedKey);
  return await crypto.subtle.unwrapKey(
    "raw",
    binaryWrappedKey,
    privateKey,
    "rsa-oaep",
    "aes-gcm",
    extractable,
    ["encrypt", "decrypt"]
  );
};

const createNewKey = async function () {
  return await crypto.subtle.generateKey(
    { name: "aes-gcm", length: 256 },
    true,
    ["decrypt", "encrypt"]
  );
};

const createNewSalt = async function () {
  const salt = new Uint8Array(256 / 8);
  await crypto.getRandomValues(salt);
  return bytesToHexString(salt);
};

const encrypt = async function (key, message) {
  const messageBinary = asciiToUint8Array(message);
  const result = await encryptBinary(key, messageBinary);
  const ciphertext =
    "" + bytesToHexString(result.iv) + bytesToHexString(result.cipherBinary);
  return ciphertext;
};

const createNewKeyPair = async function () {
  return await crypto.subtle.generateKey(
    {
      name: "rsa-oaep",
      modulusLength: 4096,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "sha-256",
    },
    true, // Needs to be extractable
    ["wrapKey", "unwrapKey"]
  );
};

const getKeyPair = async function (
  password,
  storageEngine = null,
  extractable = false
) {
  console.debug("Loading key from backend, needing password");
  const result = await $.ajax("/crypto/private_keys.json", {
    method: "GET",
  });

  if (typeof result !== "object" || !result.success) {
    console.log("----> No key available");
    return null;
  }

  let passwordCorrect = false;
  if (result.keypair.private_key.key_password_enc) {
    passwordCorrect = !!bcrypt.compareSync(
      password,
      result.keypair.private_key.key_password_enc
    );
  } else {
    // Check password with backend -- use CSRF-token
    // thus do not use $.ajax
    const result2 = await backend("/users/validate_password", "POST", {
      password: password,
    });

    passwordCorrect = !!result2.data.valid;
  }

  console.log("Password correct?", passwordCorrect);
  if (!passwordCorrect) return null;

  const salt = result.keypair.private_key.salt;
  const derivationOptions = result.keypair.private_key.key_derivation_options;
  const wrappedPrivateKey = result.keypair.private_key.ciphertext;

  const keyDerivation = await deriveKeyFromPassword(
    password,
    salt,
    derivationOptions
  );

  if (!keyDerivation) {
    console.error("Could not derive key from password");
    return null;
  }

  const publicKey = {
    id: result.keypair.public_key.id,
    key_pub: result.keypair.public_key.key,
    owner: {
      owner: result.keypair.public_key.owner.type,
      id: result.keypair.public_key.owner.id,
    },
  };

  // store the public key, if we got a storage engine
  if (storageEngine) {
    await storageEngine.setItem("public_key", publicKey);
  }

  // Convert the public key by importing it
  publicKey.key_pub = await importKeyPairPublic(publicKey.key_pub);
  const keypair = {
    publicKey,
    privateKey: await unwrapPrivateKey(
      keyDerivation.key,
      wrappedPrivateKey,
      storageEngine,
      extractable
    ),
  };

  if (keyDerivation.derivationIsDeprecated) {
    try {
      await reEncryptAndReplacePrivateKey(
        await unwrapPrivateKey(keyDerivation.key, wrappedPrivateKey, null, true),
        password
      );
      } catch (e) {
        console.log("could not reencrypt key", e);
      }
  }

  return keypair;
};

const getPublicKeyID = async function (ownerType, ownerId) {
  const result = await $.ajax("/crypto/public_keys/by_owner.json", {
    method: "GET",
    data: {
      owner: {
        id: ownerId,
        type: ownerType,
      },
      id_only: true,
    },
  });

  if (typeof result !== "object" || !result.success) {
    return null;
  }

  return result.public_key_id;
};

const getPublicKey = async function (
  ownerType,
  ownerId,
  extractable = false /* use with care! */
) {
  console.log("crypto.js getPublicKey");
  const data = {
    owner: {
      id: ownerId,
      type: ownerType,
    },
  };
  const result = await $.ajax("/crypto/public_keys/by_owner.json", {
    method: "GET",
    data,
  });
  if (typeof result !== "object" || !result.success) {
    console.log("----> No key available");
    return null;
  }

  const parsedKey = await importKeyPairPublic(
    result.public_key.key,
    extractable
  );

  return {
    id: result.public_key.id,
    key_pub: parsedKey,
    owner: {
      id: result.public_key.owner.id,
      type: result.public_key.owner.type,
    },
  };
};

const wrapKeyForPublicKey = async function (
  publicKey /* as returned from getPublicKey */,
  key
) {
  const wrappedKey = await wrapKey(publicKey.key_pub, key);
  return wrappedKey;
};

const getAndUnwrapWrappedKey = async function (
  contentType,
  contentId,
  keyPair
) {
  console.debug("Loading key from backend " + contentType + " " + contentId);
  const result = await $.ajax("/crypto/wrapped_keys/by_content.json", {
    method: "GET",
    data: {
      content: {
        id: contentId,
        type: contentType,
      },
    },
  });

  const unwrappedKey = await unwrapKey(
    keyPair.privateKey,
    result.wrapped_key.key_enc
  );

  const key = {
    id: result.wrapped_key.id,
    key: unwrappedKey,
  };

  return key;
};

const makeKeyNonExportable = async function (key) {
  if (!key.extractable) {
    return key;
  }

  const exportedKey = await crypto.subtle.exportKey("jwk", key);

  return await crypto.subtle.importKey(
    "jwk",
    exportedKey,
    key.algorithm,
    false,
    key.usages
  );
};

const getKeyPairFromStorage = async function (storageEngine) {
  // Get public key from storage
  const publicKey = await storageEngine.getItem("public_key");
  const privateKeyFromStorage = await storageEngine.getItem("private_key");

  // key is not available
  if (!publicKey || !privateKeyFromStorage) {
    return null;
  }

  // Prepare stuff
  publicKey.key_pub = await importKeyPairPublic(publicKey.key_pub);
  const privateKey = await importKeyPairPrivate(privateKeyFromStorage);

  return {
    publicKey,
    privateKey,
  };
};

const encryptFile = async function (key, file) {
  // Encrypt filename
  const encryptedFileName = await encrypt(key, file.name);

  // Encrypt file content
  const result = await readFileAsArrayBuffer(file);
  const encryptionResult = await encryptBinary(key, result);

  const encryptedFileContent = combineBuffers(
    encryptionResult.iv,
    // We have to copy it, otherwise it will not work in combineBuffers, there will only be zeros in the result
    new Uint8Array(encryptionResult.cipherBinary)
  );

  const encryptedFile = new File([encryptedFileContent], encryptedFileName, {
    type: "application/octet-stream", // encrypted = binary data
  });

  return encryptedFile;
};

const decryptFile = async function (key, cipher, filename, mimeType) {
  const ivBinary = new Uint8Array(cipher.slice(0, 12));
  const cipherBinary = new Uint8Array(cipher.slice(12));
  const fileBinary = await decryptBinary(ivBinary, key, cipherBinary);

  return new File(
    [fileBinary],
    filename,
    { type: mimeType ? mimeType : "" } // "" is default mimeType
  );
};
// PUBLIC END

// If the function has a storageEngine-option, you supply a instance that has both async getItem and async setItem
// this is used for storing stuff in the function call

export {
  generateAndUploadNewKeyPair, // Creates a new keypair for the current user (and uploads it to the backend)
  encryptPrivateKey, // Takes a password and a keypair and encrypts a private key
  getKeyPair, // Downloads the own (non-revoked) keypair from the server, parses (/decrypts) it and returns it
  getPublicKeyID, // returns the id of the public key from a given user and returns it
  getPublicKey, // Downloads and parses a public key from a given user and returns it
  createNewKey, // Creates a new key to be used for encryption of data and returns it
  wrapKeyForPublicKey, // Takes a key for encryption and a publicKey (either from getPublicKey or from keypair.publicKey) and encrypts an encryption key and uploads it to the server
  unwrapKey, // Takes a wrapped key and unwraps it for usage
  getAndUnwrapWrappedKey, // Gets a wrapped key from the backend, unwraps it und returns it
  encrypt, // Takes a key and a string and encrypts it and returns the encrypted data as a string
  decrypt, // Takes a key and a encrypted data and returns the decrypted data
  makeKeyNonExportable, // Takes a key and ensures that it is not extractable (by exporting and reimporting)
  getKeyPairFromStorage, // Takes a storageEngine-instance and imports the public and private key from there (if saved before)
  encryptFile, // Takes a key and a file and returns the encrypted file
  decryptFile, // Takes a key and an encrypted file and returns the decrypted file
};
