import localForage from "localforage";
import Cookies from "js-cookie";

import { encrypt, decrypt, createNewKey, makeKeyNonExportable, getKeyPairFromStorage, getKeyPair } from "./crypto";

const KEY_CHECK_KEY = "key_check";
const KEY_CHECK_VALUE = "key_check_value";
const KEY_VERSION_KEY = "db_version";

export default class TemporaryStorage {
  #persistentStorage = null;
  #temporaryEncryptionKey = null;
  #namespace = null;
  #version = null;

  #loadingPromise = null;

  constructor(namespace, version = 1) {
    this.#persistentStorage = localForage.createInstance({
      name: namespace,
      description: "Instance of TemporaryStorage-class",
    });
    this.#namespace = namespace;
    this.#version = version;

    // handle encryption key (load from cookie, create, ....)
    this.#loadingPromise = this.#loadTemporaryEncrptionKey();
  }

  
  async waitUnitlLoaded() {
    return this.#loadingPromise;
  }

  async setItem(storageKey, value) {
    if (this.#isReservedStorageKey(storageKey)) {
      console.error("Reserved key");
      return;
    }

    // encrypt value
    const encryptedValue = await encrypt(
      this.#temporaryEncryptionKey,
      JSON.stringify(value)
    );
    // save it
    return await this.#persistentStorage.setItem(storageKey, encryptedValue);
  }

  async getItem(storageKey, value) {
    if (this.#isReservedStorageKey(storageKey)) {
      console.error("Reserved key");
      return null;
    }

    // get value
    const encryptedValue = await this.#persistentStorage.getItem(storageKey);
    if (!encryptedValue) return null;

    // decrypt it
    try {
      const decryptedValue = await decrypt(
        this.#temporaryEncryptionKey,
        encryptedValue
      );
      return JSON.parse(decryptedValue);
    } catch (error) {
      console.error("Could not decrypt value", error);
      return null;
    }
  }

  #isReservedStorageKey(storageKey) {
    if (storageKey == KEY_CHECK_KEY) {
      return true;
    }
    return false;
  }

  async #resetStorage() {
    await this.#persistentStorage.clear();
    Cookies.remove(this.#namespace);
  }

  async #checkVersion() {
    const currentVersion = await this.#persistentStorage.getItem(KEY_VERSION_KEY);
    if(currentVersion !== this.#version) {
      console.log("Current version of crypto-database does not match. Removing database.")
      await this.#resetStorage();
    }
  }

  async #loadTemporaryEncrptionKey() {
    await this.#checkVersion();

    const savedValue = Cookies.get(this.#namespace);

    // try to parse
    if (savedValue) {
      console.debug("Previous encryption key found, try to load it");
      try {
        const parsedValue = JSON.parse(atob(savedValue));
        const importedKey = await crypto.subtle.importKey(
          "jwk",
          parsedValue,
          { name: "aes-gcm", length: 256 },
          false,
          ["decrypt", "encrypt"]
        );

        // check if key works
        const value = await this.#persistentStorage.getItem(KEY_CHECK_KEY);
        const decryptedCheck = await decrypt(importedKey, value);
        if (decryptedCheck !== KEY_CHECK_VALUE) {
          throw "Key check failed";
        }

        this.#temporaryEncryptionKey = importedKey;
      } catch (error) {
        console.error("Could not import key", error);
      }
    } else {
      console.debug("No previous encryption key found");
      await this.#resetStorage();
    }

    if (!this.#temporaryEncryptionKey) {
      const temporaryKey = await this.#createTemporaryEncryptionKey();
      this.#temporaryEncryptionKey = temporaryKey;
    }
    return;
  }

  async #createTemporaryEncryptionKey() {
    console.debug("Create new temporary encryption key");
    await this.#resetStorage();

    // create key
    const key = await createNewKey();

    // Save for future use
    const exportedKey = await crypto.subtle.exportKey("jwk", key);
    Cookies.set(this.#namespace, btoa(JSON.stringify(exportedKey)), {
      secure: window.location.href.split("/")[0] === "https:",
      sameSite: 'strict',
    });

    // Delete previous content, because it wont fit the key
    const cipher = await encrypt(key, KEY_CHECK_VALUE);
    await this.#persistentStorage.setItem(KEY_CHECK_KEY, cipher);
    await this.#persistentStorage.setItem(KEY_VERSION_KEY, this.#version);

    return await makeKeyNonExportable(key);
  }
}
