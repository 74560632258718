// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer, getConfig } from "@rails/actioncable"

// Get ActionCable-URL and append version_information
let url = getConfig('url');
if(url && url[url.length-1] != '/') url += '/';
const query_string = url + `?version=${$('meta[name=action-cable-version]').attr('content')}`

export default createConsumer(query_string)
