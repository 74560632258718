import Axios from "axios";
import { objectToFormData } from "object-to-formdata";

export default async function(path, method, payload, asFormData = false, extraOptions = {}) {
  const url = path;
  let data = payload;
  let contentType = "application/json"; // We send json by default

  // We want to send as form data (for example if we transfer a file)
  if(asFormData) {
    data = objectToFormData(payload);
    contentType = null;
  }

  const headers = {
    "Content-Type": contentType,
    "X-CSRF-Token": getCSRFToken(),
  };

  // Request options
  const options = {
    headers,
    method,
    url,
  }

  if(method.toUpperCase() == 'GET') {
    options.params = payload;
    delete options.headers["Content-Type"];
  } else {
    options.data = data;
  }

  console.debug("Calling " + method.toUpperCase() + " " + path);

  return await Axios({
    ...options,
    ...extraOptions,
  });

};

const getCSRFToken = function() {
  if(window.CSRF_Token) return window.CSRF_Token;
  for( const metaTag of document.getElementsByTagName('meta')) {
    if(metaTag.getAttribute('name') === 'csrf-token') {
      return metaTag.getAttribute('content');
    }
  }
  return null;
}
