// Helper function to import a subdirectory into
// paedcompenda."key_name" or directly into key_name (if you give a hash)
// It always imports the default module
export default function (keyOrStore, webpackContext) {
  let result;
  if (typeof keyOrStore === "string") {
      window.praxisapp = window.praxisapp || {};
      window.praxisapp[keyOrStore] = window.praxisapp[keyOrStore] || {};
      result = window.praxisapp[keyOrStore];
  }
  else {
      result = keyOrStore;
  }
  webpackContext.keys().forEach((key) => {
      const moduleName = key
          .split("/")
          .slice(-1)[0]
          .split(".")[0];
      try {
          result[moduleName] = webpackContext(key).default;
      }
      catch (e) {
          // tslint:disable-next-line: no-console
          console.error(`Could not load module '${key}'.`, e);
      }
  });
}
