import TemporaryStorage from "./temporary-storage";

// open temp storage
const storageInstance = new TemporaryStorage('crypto_session_storage', 2); // Name has to be changed in Devise::SessionsController#destroy and SwitchUserController#set_current_user too

// This makes it possible to share a single instance throughout multiple places
export default async function() {
  await storageInstance.waitUnitlLoaded();
  return storageInstance;
}
