
const readFileAsArrayBuffer = function(file){
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result )
    };
    fr.onerror = () => {
      reject("An error occured reading the file");
    }
    fr.readAsArrayBuffer(file);
  });
}

const combineBuffers = function(...buffers){
  // Get totalSize of result and create it
  const totalSize = buffers.reduce((cur, buffer) => cur + buffer.byteLength, 0);
  const result = new Uint8Array(totalSize);

  // Copy buffers over on at a time
  let offset = 0;
  for( const buffer of buffers ) {
    result.set(buffer, offset);
    offset = offset + buffer.byteLength;
  }

  return result;
}

export {
  readFileAsArrayBuffer, // Takes a File instance and reads it from the disk (promise-style)
  combineBuffers, // Takes multiple buffers as input and combines them to one
}
